import React from "react"
import { Link } from "gatsby"

import Blog from '../components/blog/es'
import LeftColumn from '../components/blog/left-column/es'
import RightColumn from '../components/blog/right-column/es'
import PostSummary from '../components/blog/post-summary/es'
import PostIndex from '../components/blog/post-index/es'
import Author from '../components/blog/author/es'


export default (props) => (
  <Blog  {...props}>
    <LeftColumn {...props} >
      <Author {...props} />

    </LeftColumn>

    <RightColumn {...props}>
      <PostIndex />
    </RightColumn>
  </Blog>
)

