import React from 'react';

import Author from './base'
import messages from '../../../data/messages/es';

export default (props) => {
  const title = 'El Autor';
  const description = `Entusiasta de las Ciencias de la Computación. Apasionado por la construcción de sistemas distribuidos, equipos multiculturales y productos innovadores. Experiencia  en blockchain y computación en la nube. Habilidades de gestión y liderazgo.

`;
  const available = 'Disponible para contratar';
  const url = "https://mfranco.backendhelpers.co";

  return (
    <>
    <Author lang="es" title={messages.title} subTitle={messages.subTitle} description={description} available={available} url={url}/>
    </>
  )
}



