import React from 'react';
import Blog from './base';
import messages from '../../data/messages/es';


export default (props) => (
  <Blog
    {...props}
    locale='es'
    messages={messages}
  />
);
